import React from 'react'
import { Typography } from '@creditas/typography'
import { styled, utilities } from '@creditas/stylitas'

interface StyleProps {
  noMargin?: boolean
  color?: string
}
interface Props extends StyleProps {
  total?: string
  title: string
}

const Div = styled.div`
  text-align: center;
  width: 100%;
  ${({ noMargin }: StyleProps) => (!noMargin ? `margin-bottom: 38px` : '')};
`

const TotalAmount = styled.strong`
  display: block;
  color: ${({ theme, color }) => utilities.palette.color(theme, color)};
  ${({ theme }) => utilities.typography.h4(theme)};
  ${({ noMargin }: StyleProps) => (!noMargin ? `margin-top: 17px;` : '')};
`

const OverviewAmountTotal = ({
  title,
  total,
  noMargin = false,
  color = 'primary04',
}: Props) => {
  return (
    <Div noMargin={noMargin}>
      <Typography variant="supportForm">{title}</Typography>
      <TotalAmount
        data-testid="overview-amount-total"
        noMargin={noMargin}
        color={color}
      >
        {total}
      </TotalAmount>
    </Div>
  )
}

export { OverviewAmountTotal }
