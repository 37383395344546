import { styled, utilities } from '@creditas/stylitas'

const OverviewBox = styled.div`
  ${utilities.border.thin};
  ${utilities.radius.small};
  ${({ theme }: any) => `border-color: ${theme.bColorneutral03}`};
  padding: 35px;
  background-color: white;

  @media (max-width: 450px) {
    padding: 23px;
  }
`

export { OverviewBox }
