import React from 'react'
import { Typography } from '@creditas/typography'
import { styled, utilities, css } from '@creditas/stylitas'
import { monthAndYearFormat } from '../../helpers'

interface Props {
  text: string
  period?: string
  fontSize?: string
}

const Strong = styled.h4`
  ${({ theme }) => `color: ${theme.bColorneutral08}`};
  ${({ theme }) => utilities.typography.h4(theme)};

  ${(props: any) =>
      props.fontSize === 'small' &&
      css`
        font-size: 16px;
        line-height: 20px;
      `}
    ::first-letter {
    text-transform: capitalize;
  }
`

const OverviewPeriod = ({ text, period, fontSize = 'large' }: Props) => {
  const monthAndYear = monthAndYearFormat(period)
  return (
    <>
      <Typography variant="supportForm">{text}</Typography>
      <Strong fontSize={fontSize} data-testid="overview-period">
        {monthAndYear}
      </Strong>
    </>
  )
}

export { OverviewPeriod }
