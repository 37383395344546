import React from 'react'
import { Details } from '../'
import { useTranslation } from 'react-i18next'
import { Policy } from '../../services/bff/types'
import { formatCurrency } from '../../helpers'

interface Props {
  buttonText: string
  policyData?: Policy
}

const OverviewKnowMore: React.FC<Props> = ({ buttonText, policyData }) => {
  const { t } = useTranslation()

  enum KeyTextToBeDisplayed {
    FREE = 'FREE',
    FIRSTS_FREE_THEN_PAID = 'FIRSTS_FREE_THEN_PAID',
    PAID = 'PAID',
  }

  const fee = Number(policyData?.fee ?? 0)
  const currencyFee = formatCurrency(fee)

  const firstPaidSalaryAdvance =
    (policyData?.freeSalaryAdvancesPerUser ?? 0) + 1

  const textsToBeDisplayed = {
    [KeyTextToBeDisplayed.FREE]: 'freeTax',
    [KeyTextToBeDisplayed.FIRSTS_FREE_THEN_PAID]: 'overviewKnowMore',
    [KeyTextToBeDisplayed.PAID]: 'paidTax',
  }

  function getTextKey(): KeyTextToBeDisplayed {
    if (fee === 0) return KeyTextToBeDisplayed.FREE
    else if (policyData?.freeSalaryAdvancesPerUser === 0)
      return KeyTextToBeDisplayed.PAID
    else return KeyTextToBeDisplayed.FIRSTS_FREE_THEN_PAID
  }
  const textKey = getTextKey()

  const paragraphs: Array<string> = t(`shared:${textsToBeDisplayed[textKey]}`, {
    returnObjects: true,
    ...policyData,
    firstPaidSalaryAdvance,
    fee: currencyFee,
  })

  return (
    <Details buttonText={buttonText} data-testid="overview-know-more">
      {paragraphs.map((v, i) => (
        <p key={i}>{v}</p>
      ))}
    </Details>
  )
}

export { OverviewKnowMore }
