import React from 'react'

import { Button } from './Confirmation.style'
import { useTranslation } from 'react-i18next'
import { Typography } from '@creditas/typography'

import {
  OverviewBox,
  OverviewPeriod,
  OverviewDetails,
  OverviewKnowMore,
  OverviewDetailItem,
  OverviewAmountTotal,
} from '../../components/Overview'
import { Confirmation, Banker } from '../../services/bff/types'
import { QueryResult } from '@apollo/react-common'
import { formatCurrency } from '../../helpers'
import { useGetPolicy } from '../../services/bff/queries/getPolicy'

export interface ContractInfo {
  banker: Banker
  signatureKey: string
  salaryAdvanceId: string
  fee: number
  freeAfterThreshold: number
}
interface Props {
  clientId: number
  request: (clientId: number) => QueryResult<Confirmation>
  onLoad: (contractInfo: Partial<ContractInfo>) => string
}

const Confirmation = ({ clientId, request, onLoad }: Props) => {
  const { data, stopPolling } = request(clientId)
  const { data: { policy } = {} } = useGetPolicy(clientId)

  const getContractInfo = (data?: Confirmation): Partial<ContractInfo> => {
    return {
      banker: data?.salaryAdvanceConfirmation.banker,
      signatureKey: data?.salaryAdvanceConfirmation.signatureKey,
      salaryAdvanceId: data?.salaryAdvanceConfirmation.salaryAdvanceId,
      fee: policy?.fee,
      freeAfterThreshold: policy?.freeAfterThreshold,
    } as ContractInfo
  }

  const isSocinal = (data?: Confirmation) =>
    data?.salaryAdvanceConfirmation.banker === Banker.Socinal

  const isScdAndHasSignatureKey = (data?: Confirmation) =>
    data?.salaryAdvanceConfirmation.banker === Banker.Scd &&
    data?.salaryAdvanceConfirmation.signatureKey

  onLoad(getContractInfo(data))

  if (isSocinal(data) || isScdAndHasSignatureKey(data)) stopPolling()
  const { t } = useTranslation()
  return (
    <OverviewBox>
      <OverviewAmountTotal
        title={t('overview.textAmountTotal')}
        total={formatCurrency(data?.salaryAdvanceConfirmation.amount)}
      />

      <OverviewPeriod
        text={t('overview.textPeriod')}
        period={data?.salaryAdvanceConfirmation.currentCycleEndDate}
        fontSize="small"
      />

      <OverviewDetails>
        <OverviewDetailItem
          label={t('overview.details.monthlyMaxWithdrawal')}
          value={formatCurrency(
            data?.salaryAdvanceConfirmation
              .totalAmountAdvanceableInCurrentCycle,
          )}
        />
        <OverviewDetailItem
          label={t('overview.details.alreadyDrawn')}
          value={formatCurrency(
            data?.salaryAdvanceConfirmation.amountAdvancedInCurrentCycle,
          )}
        />
        <OverviewDetailItem
          label={t('overview.details.tax')}
          value={formatCurrency(data?.salaryAdvanceConfirmation.fee)}
        />
        <OverviewDetailItem
          label={t('overview.details.amountDiscounted')}
          value={formatCurrency(
            data?.salaryAdvanceConfirmation.amountToBeDiscounted,
          )}
        />
      </OverviewDetails>

      {policy?.freeAfterThreshold && (
        <>
          <Typography variant="supportForm">
            <b style={{ fontWeight: 'bold' }}>
              {t('overview.warning.highlight')}
            </b>{' '}
            {t('overview.warning.text', policy)}
          </Typography>
          <OverviewKnowMore
            buttonText={t('overview.actionKnowMore')}
            policyData={policy}
          />
        </>
      )}
      {/* <Button variant="secondary">{t('overview.action')}</Button> */}
    </OverviewBox>
  )
}

export { Confirmation }
