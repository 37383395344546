import React from 'react'
import { TranslatedErrorBoundary } from '../../components'
import { ThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from '../../services/bff/apollo-client'

import { Confirmation } from './Confirmation'
import { useGetConfirmation } from '../../services/bff/queries/getConfirmation'
import { ConfirmationCustomProps } from './index.spa'

class App extends React.Component<ConfirmationCustomProps> {
  componentDidCatch() {}

  render() {
    const { authToken, clientId, onLoad } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={{ ...tokens }}>
            <Confirmation
              request={useGetConfirmation}
              clientId={clientId}
              onLoad={onLoad}
            />
          </ThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
